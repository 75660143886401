<template>
  <div>
    <div class="todos-container">
      <div class="v-calendar">
        <v-date-picker
          class="v-date-picker"
          color="red"
          :is-dark="isDark()"
          is-range
          v-model="vCalendarData.selectedDate"
          @input="handleChange"
        />

      </div>
      <div class="todo-app">
        <h1 class="todo-title">Todos</h1>
        <ul class="todo-list">
          <li @click="updateTask(index,{id:task.id,status:task.status=='doing'?'done':'doing'})" v-for="task,index in taskList" :key="index" class="todo" :class="{'todo--completed':task.status=='done'}">
            <span class="todo__content">{{ task.title }}</span>
            <i @click.stop="showDesc=index" class="fa fa-list-ul" style="margin-left:10px;" ></i>
            <i @click.stop="addTask=task,showEdit=index" style="margin-left:10px;" class="fa fa-pencil-square-o" aria-hidden="true"></i>
          </li>
        </ul>
        <div class="add-todo">
          <input class="add-todo__input" v-model="addTask.title" placeholder="添加一个任务标题...">
          <button class="add-todo__btn" @click="saveTask()"><i class="fa fa-plus"></i></button>
        </div>
      </div>
      <div v-if="showEdit!=-1" @click="showEdit=-1" style="background: #00000055; z-index: 1;width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;">
      <div @click.stop="showEdit" class="todos-edit">
        <h1 class="todo-title">Todos Edit</h1>
        <div class="todos-input-line" v-for="(value, key) in addTask" :key="key">
            <label class="todos-edit-label"  :for="key">{{ key }}:</label>
            <el-date-picker
              v-if="typeof value === 'string' && value.match(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/)"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="addTask[key]"
            ></el-date-picker>
            <el-input
              v-else-if="typeof value === 'string' || typeof value === 'number'" 
              :type="typeof value === 'number' ? 'number' : 'textarea'" :id="key" 
              v-model="addTask[key]"
              placeholder="请输入一些文本"
          ></el-input>
            <el-input-number  v-else-if="typeof value === 'number'"  :id="key"  v-model="addTask[key]"
            ></el-input-number>
            <el-input
              v-else 
              type="text" 
              v-model="addTask[key]"
              placeholder="请输入一些文本"
          ></el-input>
          </div>
          <button class="todos-edit-save-btn" @click.stop="updateTask(showEdit,addTask),showEdit=-1">保存</button>
        </div>
    </div>
    <div v-if="showDesc!=-1" @click="showDesc=-1" style="background: #00000055; z-index: 1;width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;">
      <div @click.stop="showEdit" class="todos-desc">
        <h1 class="todo-title">{{taskList[showDesc].title}}</h1>
        <div class="todos-desc-line">{{taskList[showDesc].status}}</div>
        <div class="todos-desc-line">从{{taskList[showDesc].startTime}} <br/> 到{{taskList[showDesc].endTime}}</div>
        <div class="todos-desc-line">执行者：{{taskList[showDesc].executor}}</div>
        <div class="todos-desc-main" v-html="taskList[showDesc].description"></div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import DatePicker from 'v-calendar/lib/components/date-picker.umd'
  Vue.component('v-date-picker', DatePicker)
  // Register components in your 'main.js'
  export default {
    components: {
      "v-date-picker":DatePicker
    },

    data() {
      return {
        card: null,
        vCalendarData:{
          selectedDate:{start:new Date(new Date().getDay()-2),end:new Date(new Date().getDay()+2)}
        },
        wallpaperIndex:0,
        showEdit:-1,
        showDesc:-1,
        wallpapers:[],
        collects: {},
        taskList:[],
        addTask:{
          "title": "每日提交代码",
          "dependencyId": 0,
          "description": "这里是简介",
          "status": "doing",
          "startTime": this.formatDateToString(new Date()),
          "endTime": this.formatDateToString(new Date()),
          "urgency": 1,
          "executor": "0",
        },
      }
    },

    computed: {},

    watch: {

    },

    created() {
      let current = new Date()
      this.vCalendarData.selectedDate.start = new Date().setDate(current.getDate() - 2)
      this.vCalendarData.selectedDate.end = new Date().setDate(current.getDate() + 2)
      this.handleChange(this.vCalendarData.selectedDate)
    },

    mounted() {
      
    },

    methods: {
      isDark(){
        return document.documentElement.getAttribute('theme')=='dark';
      },
      saveTask(task) {
        task = task??this.addTask;
        this.$http.post(this.$constant.baseURL + "/todolist/saveTask", task, true)
          .then((res) => {
            if (res.code == 200) {
              this.taskList.push(task);
              this.addTask.title = "";
            }
          })
          .catch((error) => {
            this.$message({
              message: error.message,
              type: "error"
            });
          });
      },
      updateTask(index,task) {
        this.$http.post(this.$constant.baseURL + "/todolist/updateTask", task,true)
          .then((res) => {
            if (res.code == 200) {
              this.taskList[index].status = res.data.status;
            }
          })
          .catch((error) => {
            this.$message({
              message: error.message,
              type: "error"
            });
          });
      },
      getTasks(selectedDate) {
        this.$http.post(this.$constant.baseURL + "/todolist/listTask", selectedDate)
          .then((res) => {
            if (!this.$common.isEmpty(res)) {
              this.taskList = res.data
            }
          })
          .catch((error) => {
            this.$message({
              message: error.message,
              type: "error"
            });
          });
      },
      handleChange(newDate){
        const startDate = new Date(newDate.start);
        startDate.setHours(0, 0, 0, 0); // 将时间部分设置为 0
        const endDate = new Date(newDate.end);
        endDate.setHours(23, 59, 59, 999); // 将时间部分设置为 0
        this.getTasks({startTime:this.formatDateToString(startDate),endTime:this.formatDateToString(endDate)})
      },
      formatDateToString(fdate){
        let date = new Date(fdate); // 获取当前时间
        let year = date.getFullYear().toString().padStart(4, '0'); // 年份
        let month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份
        let day = date.getDate().toString().padStart(2, '0'); // 日期
        let hour = date.getHours().toString().padStart(2, '0'); // 小时
        let minute = date.getMinutes().toString().padStart(2, '0'); // 分钟
        let second = date.getSeconds().toString().padStart(2, '0'); // 秒钟
        let datetimeString = `${year}-${month}-${day} ${hour}:${minute}:${second}`; // 生成日期时间字符串
        return datetimeString
      }
    }
  }
</script>

<style scoped>
  .todos-container {
    flex-direction: column;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga", "kern";
  }
  .index-video {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    min-height: 400px;
    width: 1200px;
    object-fit: cover;
    animation-direction: alternate;
    animation: move-video 38s infinite;
  }
  @keyframes move-video {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(calc(-100% + 400px));
    }
    100% {
      transform: translateY(0%);
    }
  }
  @media screen and (max-width: 620px) {
    .index-video {
      width: auto;
      height: 400px;
    }
    @keyframes move-video {
      0% {
        transform: translateX(0);
      }
      50% {
        transform: translateX(calc(-100% + 100vw - 50px));
      }
      100% {
        transform: translateX(0);
      }
    }
    .card-container {
      margin-top: 0;
    }
  }
  .todos-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .todo-app {
    width: 100%;
    max-width: 400px;
    font-size: 18px;
    background: var(--background);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    border-radius: 5px;
    box-shadow: 0 1px 20px -6px var(--borderColor);
    margin-top: .5rem;
    padding: 1rem 0;
    margin: .8rem 0;
  }
  .todo-title {
    margin-bottom: auto;
    margin-top: 0;
    line-height: 1;
  }
  .todo-list {
    font-size: 100%;
    list-style: none;
    width: 100%;
    padding: 0 .9rem;
    overflow-x: hidden;
    margin-top: 2em;
  }
.todo {
  display: flex;
  align-items: center;
  line-height: 1.5;
  position: relative;
  padding: 0 2.5rem 0 .75rem;
  transition: transform 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.todo:after {
  font-family: FontAwesome;
  content: "\f00c";
  position: absolute;
  right: .75rem;
  color: #2ecc71;
  opacity: 0;
  transform: scale(0);
  transition: transform, opacity, 0.25s cubic-bezier(0.55, 0, 0.1, 1);
}

.todo + .todo {
  margin-top: .5rem;
  padding-top: .5rem;
}

.todo + .todo:before {
  content: '';
  background: #e8ebed;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  border-radius: 2px;
}

.todo__content {
  position: relative;
  opacity: 1;
  text-transform: capitalize;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity 0.25s ease-out;
}

.todo__content:after {
  content: '';
  position: absolute;
  display: block;
  width: calc( 100% + .5em);
  left: -.25em;
  height: 2px;
  top: 50%;
  border-radius: 1px;
  background: var(--fontColor);
  transform-origin: center right;
  transform: scaleX(0);
  transition: transform 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.todo.todo--completed:after {
  opacity: 1;
  transform: scale(1);
}

.todo.todo--completed .todo__content {
  opacity: 0.5;
}

.todo.todo--completed .todo__content:after {
  transform: scaleX(1);
  transform-origin: center left;
}
.todo-enter {
  opacity: 0;
  padding-top: 0;
  margin-top: 0;
  max-height: 0;
  transform: translateX(-100%);
  transition: opacity 500ms ease-out, transform 750ms cubic-bezier(0.87, -0.41, 0.19, 1.44), max-height 500ms ease-out, padding 500ms ease-out, margin 500ms ease-out;
}

.todo-enter.todo-enter-active {
  opacity: 1;
  max-height: 100px;
  transform: translateX(0);
}

.todo-leave {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms ease-out, transform 750ms cubic-bezier(0.87, -0.41, 0.19, 1.44), max-height 500ms ease-out, padding 500ms ease-out, margin 500ms ease-out;
}

.todo-leave.todo-leave-active {
  opacity: 0;
  padding-top: 0;
  margin-top: 0;
  max-height: 0;
  transform: translateX(100%);
}

.add-todo {
  width: 75%;
  position: relative;
  margin: 1rem 0;
}

.add-todo__btn {
  text-align: center;
  color: #fafafa;
  line-height: 1;
  position: absolute;
  font-size: 120%;
  right: 0;
  top: 50%;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background: #2ecc71;
  opacity: 0;
  transform: translate(0, -50%) scale(0) rotate(180deg);
  transition: transform, opacity, 0.5s cubic-bezier(0.87, -0.41, 0.19, 1.44);
}

.add-todo__btn:focus {
  opacity: 1;
  transform: translate(50%, -50%) scale(1.1) rotate(0deg);
}

.add-todo__btn:active {
  opacity: 1;
  transition: transform, opacity, 0.1s cubic-bezier(0.87, -0.41, 0.19, 1.44);
  transform: translate(50%, -50%) scale(0.95) rotate(0deg);
}

.add-todo__input {
  width: 100%;
  border-radius: 5px;
  padding: .5rem 1rem;
  background: #e8ebed;
  margin: 0;
  outline: 0;
  border: 2px solid #e8ebed;
  transition: background .25s ease-out;
}

.add-todo__input:focus {
  background: none;
}

.add-todo__input:focus + .add-todo__btn {
  opacity: 1;
  transform: translate(50%, -50%) scale(1) rotate(0deg);
}

.filters .filter__link {
  margin: 0 1em;
  font-size: 150%;
}

.filters .fa-list-ul {
  color: #3498db;
}

.filters .fa-times {
  color: #e74c3c;
}

.filters .fa-check {
  color: #2ecc71;
}

.filters .filter__link i {
  transition: transform 0.5s cubic-bezier(0.87, -0.41, 0.19, 1.44);
}

.filters .filter__link i:hover {
  transform: scale(1.1);
}

.filters .filter__link i:active {
  transform: scale(0.95);
}

i,button{
  cursor: pointer;
}
.todos-desc{
  width: 90vw;
  max-width: 400px;
  height: 480px;
  background-color: var(--background);
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: #3e3e3e 1px 5px 20px;
  border-radius: 8px;
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transition: .3s;
}
.todos-desc-line{
  padding: 2px 0px 0px 10px; 
}
.todos-desc-main{
  overflow-y: scroll;
  flex:1;
}
.todos-edit{
  width: 90vw;
  max-width: 400px;
  height: 480px;
  overflow-y: scroll;
  background-color: var(--background);
  padding: 20px;
  box-shadow: #3e3e3e 1px 5px 20px;
  border-radius: 8px;
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transition: .3s;
  z-index: 115;
  /* height: 0; */
}
.todos-edit.show{
  height: unset;
}
.todos-input-line{
  margin: 5px;
}
.todos-edit-label {
  color: var(--fontColor);
  vertical-align: middle;
  width: 100px;
  height: 36px;
  line-height: 36px;
  overflow: hidden;
  padding-right: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.todos-edit-save-btn{
  background: var(--themeBackground);
  width: 100%;
  height: 40px;
  color: #FFF;
  text-align: center;
  outline: none;
  border: none;
}
@media screen and (max-width: 620px) {
    .v-date-picker{
      width: 90vw;
    }
    .todos-container{
      flex-direction: column;
    }
  }
  @media screen and (max-width: 900px) {
    .favorite-item {
      width: calc(100% / 3 - 20px);
    }
  }

  @media screen and (max-width: 700px) {
    .favorite-item {
      width: calc(100% / 2 - 20px);
    }
  }

  @media screen and (max-width: 400px) {
    .favorite-item {
      width: calc(100% - 20px);
    }
  }
</style>